<template>
    <div>
        <div v-if="isDivision">
            <DivisionDashboard></DivisionDashboard>
        </div>
        <v-content v-else>
            <v-breadcrumbs :items="items" large></v-breadcrumbs>
            <v-row>
                <v-col cols="12">
                    <v-row justify="start">
                        <v-col
                                cols="2"
                                v-for="title in titles"
                                v-bind:key="title.link"
                                class="viewCard"
                        >
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <router-link :to="title.link">
                                        <HoverCard :cardData="title"></HoverCard>
                                        <!--<v-card
            :elevation="10"
            class="blue darken-1 text-center"
            height="100"
            width="200"
    >
        <v-list-item three-line>
            <v-list-item-content>
                <v-list-item-title
                        class="white--text subtitle-1 mt-1 pa-4"
                >{{title.name}}
                </v-list-item-title>
                <v-list-item-subtitle class="white--text header-6 mt-1">
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-card>-->
                                    </router-link>
                                </template>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-content>
    </div>
</template>

<script>
    import HoverCard from '@/components/Basic/HoverCard'
    import axios from "axios";
    import DivisionDashboard from '../../dashboard/DivisionDashboard'
    import {mapGetters} from "vuex";

    export default {
        name: "Dashboard",
        watch: {},
        components: {
            DivisionDashboard,
            HoverCard
        },
        computed: {
            ...mapGetters(['getSystemUserData']),
            isDivision() {
                return this.getSystemUserData.role === 'Division'
            },
            titles() {
                return [
                    {
                        name: this.$t('profile'),
                        link: "/branch",
                        icon: "mdi-robot"
                    },
                    {
                        name: this.$t('customer'),
                        link: "/Tourist-VehicleCustomerList",
                        icon: "mdi-human-male-female"
                    }
                ]
            },
            items() {
                return [
                    {
                        text: this.$t('dashboard'),
                        disabled: true,
                        to: "/dashboard",
                        exact: true
                    }
                ]
            }
        },
        data() {
            return {
                branchInformation: [],
                roomCount: ""
            }
        },
        mounted() {
            this.formData()
        },
        methods: {
            async formData() {
                const dashboardData = await axios.get(
                    "Branch/GetBranchInformationByBranchID"
                );
                this.branchInformation = dashboardData.data;

                const availableCount = await axios.get('Hotel/RoomStatusCountAsync', {
                    params: {
                        ShowCount: true
                    }
                })
                this.roomCount = availableCount.data
            },
        }
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>